@charset "utf-8";
.errMsg {
	margin-left: 1em;
	padding-bottom: 1px;
	display: block;
	line-height: 1.4;
	text-indent: -.9em;
}

.formError {
	padding-bottom: 13px;
	display: block;
}
.fixed {
	padding-bottom: 0px;
}

	.formError .formErrorClose {
		border: solid #252525 2px;
		border-radius: 9px 9px 9px 9px;
		-moz-border-radius: 9px 9px 9px 9px;
		-webkit-border-radius: 9px 9px 9px 9px;
		display: block;
		width: 16px;
		height: 16px;
		position: absolute;
		top: -4px;
		right: -4px;
		color: #efefef;
		background: #333;
		font-weight: bold;
		text-align: center;
		line-height: 12px;
		cursor: pointer;
		box-shadow: 1px -1px 3px #888;
		-moz-box-shadow: 1px -1px 3px #888;
		-webkit-box-shadow: 1px -1px 3px #888;
    _z-index:2;
	}
	.formError .formErrorClose:hover {
		background: #666;
	}
	.fixed .formErrorClose {
		display: none;
	}
	
	.formError .formErrorContent {
		border-radius: 3px;
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		padding: 6px 10px;
		width: 180px;
    position:relative;
		color: #fff;
		background: #252525;
		font-family: "Lucida Sans Unicode","Lucida Grande",Arial,Helvetica,"ヒラギノ丸ゴ Pro W4",HiraMaruPro-W4,"ヒラギノ角ゴ Pro W3","メイリオ",Meiryo,Osaka,sans-serif;
		font-size: 11px;
		box-shadow: 0px 0px 6px #888;
		-moz-box-shadow: 0px 0px 6px #888;
		-webkit-box-shadow: 0px 0px 6px #888;
		margin: 0 0 30px 0;
    _z-index:1;
	}
	.fixed .formErrorContent {
		margin-top: .5em;
		border-radius: 0;
		-moz-border-radius: 0;
		-webkit-border-radius: 0;
		background: #f60;
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		margin: 0 0 30px 0;
	}
	.fadeOut {
		opacity: .2;
		filter: alpha(opacity=20);
	}
	
	.formError .formErrorArrow {
		width: 15px;
		height: 15px;
		position: absolute;
		bottom: 0;
		left: 20px;
		margin: 0 0 30px 0;
    _z-index:0;
	}
	.fixed .formErrorArrow {
		display: none;
	}
	.formError .formErrorArrowBottom {
		margin: -6px;
		top: 0;
	}
	.fixed .formErrorArrowBottom {
		display: none;
	}
	
		.formError .formErrorArrow div {
			margin: 0 auto;
			display: block;
			height: 1px;
			background: #252525;
			line-height: 0px;
			font-size: 0px;
			box-shadow: 0px 2px 3px #888;
			-moz-box-shadow: 0px 2px 3px #888;
			-webkit-box-shadow: 0px 2px 3px #888;
		}
			.formError .formErrorArrowBottom div{
				box-shadow: none;
				-moz-box-shadow: none;
				-webkit-box-shadow: none;
			}
			
		.formError .formErrorArrow .line10 {
			width: 19px;
		} 
		.formError .formErrorArrow .line9 {
			width: 17px;
		} 
		.formError .formErrorArrow .line8 {
			width: 15px;
		} 
		.formError .formErrorArrow .line7 {
			width: 13px;
		} 
		.formError .formErrorArrow .line6 {
			width: 11px;
		} 
		.formError .formErrorArrow .line5 {
			width: 9px;
		} 
		.formError .formErrorArrow .line4 {
			width: 7px;
		} 
		.formError .formErrorArrow .line3 {
			width: 5px;
		} 
		.formError .formErrorArrow .line2 {
			width: 3px;
		}
		.formError .formErrorArrow .line1{
			width: 1px;
		}  

/* dislay */
.hid_url { display:none;}
/* contact */
#contact {
	#wrap {
		background: url('../img/common/other/contact_bg.gif') repeat 0 0;
		padding: 0 0 100px;
	}
	form.form-1 {
		max-width:1000px;
		padding: 55px 0 90px;
		border-bottom: 2px solid #bec6d5;
		margin: 50px auto 0;
		background: #fff;
	}
	.formBlock {
		max-width:700px;
		margin: 0 auto;
	}
	.txtContact {
		text-align:center;
		font-size:15px;
	}
	.tableContact em {
		color:#e50000;
		font-style:normal;
		display: inline-block;
		margin-left: 12px;
		font-weight: normal;
		font-size: 13px;
	}
	.tableContact {
		width:100%;
		margin:40px 0 0;
		font-size:15px;
		text-align:left;
	}
	.tableContact th{
		padding: 30px 0 10px;
		vertical-align:top;
		width:210px;
		font-weight:bold;
	}
	.tableContact td {
		padding: 15px 0;
	}
	.tableContact input[type="email"],
	.tableContact input[type="number"],
	.tableContact input[type="tel"],
	.tableContact input[type="text"] {
		width:100%;
		height:50px;
		background: #f5f5f5;
		@include border-radius(3px);
	}
	.tableContact textarea {
		height:140px;
		display: block;
		@include border-radius(3px);
		background: #f5f5f5;
		width: 100%!important;
	}
	.tableContact input, .tableContact textarea {
		border:#f5f5f5 solid 1px;
		padding: 5px 10px;
	  -webkit-appearance: none;
	  -moz-appearance: none;
	  appearance: none;
	}
	.btnConfirm {
		text-align: center;
		button {
			outline: none;
			margin-top: 35px;
			width: 400px;
			background: #3dcec5;
			border-bottom: solid 5px #36b1ab;
		}
	}

	.containerIndexThx {
		max-width: 700px;
		margin: 50px auto 100px;
		background: #fff;
		padding: 50px;
		font-size: 16px;
		p {
			margin-top: 50px;
			&:first-child {margin-top: 0;}
		}
	}
	@include max-screen(767px) {
		#wrap {
			padding: 0 0 40px;
		}
		.containerIndexThx {
			margin: 20px 10px 50px;
			padding: 30px;
			font-size: 14px;
			p {
				margin-top: 30px;
				&:first-child {margin-top: 0;}
			}
		}
		form.form-1 {
			padding: 20px 10px 0;
			border-bottom: none;
			margin: 0;
			background: none;
			width: 100%;
			.header__top__right {
				display: block;
				float: none;
				text-align: center;
			}
		}
		.tbInfo {
			background: #fff;
			padding: 0 15px 30px;
			border-bottom: 2px solid #bec6d5;
			margin-top: 20px;
		}
		@include max-screen(320px) {
			.g-recaptcha > div > div iframe,
			.g-recaptcha > div {
				width: 100%!important;
			}

		}
		.txtContact {
			text-align:left;
			font-size:12px;
		}
		.tableContact em {
			margin-left: 7px;
			font-size: 10px;
		}
		.tableContact {
			margin: 0;
			font-size:12px;
		}
		.tableContact th{
			padding: 15px 0 8px;
			width:100%;
			display: block;
			font-weight: normal;
		}
		.tableContact td {
			padding: 0;
			display: block;
			width: 100%;
		}
		.tableContact input[type="email"],
		.tableContact input[type="number"],
		.tableContact input[type="tel"],
		.tableContact input[type="text"] {
			height:40px;
		}
		.tableContact textarea {
			height:150px;
		}
		.btnConfirm {
			text-align: center;
			button {
				outline: none;
				margin-top: 30px;
				width: 100%;
				background: #3dcec5;
				border-bottom: solid 3px #36b1ab;
			}
		}
	}
}