@charset "UTF-8";
@import "mixin";
/*-----------------------------------------------------------
clearfix
------------------------------------------------------------*/
.clearfix:after {
	content: "";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
	line-height: 0;
}
.clearfix {display: inline-block;}
/* exlude MacIE5 \*/
* html .clearfix {height: 1%}
.clearfix {display: block;}
/* end MacIE5 */
/*-----------------------------------------------------------
reset
------------------------------------------------------------*/
img {
	margin: 0;
	padding: 0;
	vertical-align: bottom;
	border: 0;
}
table img {
	margin: 0;
	padding: 0;
	vertical-align: bottom;
	border: 0;
}
p {
	margin: 0;
	padding: 0;
}
ol, ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
input, textarea {
	margin: 0;
	font-size: 100%;
	resize: none;
}
table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 100%;
}
dl, dt, dd, th, td {
	margin: 0;
	padding: 0;
}
h1, h2, h3, h4, h5, h6 {
	/*font-size: 100%;*/
	font-weight: normal;
	margin: 0;
	padding: 0;
}
pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
article, aside, dialog, figure, footer, header, hgroup, nav, section {display: block;}
nav ul {list-style: none;}

/*-----------------------------------------------------------
Link
------------------------------------------------------------*/
a {
	outline: none;
	transition:0.3s;
}
a:link {
	color: $colortext;
	text-decoration: none;
}
a:visited {
	color: $colortext;
	text-decoration: none;
}
a:hover {
	color: #aaa;
	text-decoration: none;
}
a:active {
	color: $colortext;
	text-decoration: none;
}

/*-----------------------------------------------------------
FONT family
------------------------------------------------------------*/
.ffN {font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;}
.ffM {font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", serif;}

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float:right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}

.contentcms img {
	max-width:100%;
	height:auto;
	max-height:100%;
	width:100%;
}

/* Text meant only for screen readers. */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
        white-space: nowrap;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar. */
}
/* start WordPress */
.wp-pagenavi {
	clear: both;
	text-align: center;
	font-size:16px;
	margin: 20px 0 80px;
	position: relative;
	@include max-screen(768px) {
		margin: 15px 0 25px;
		background: #fff;
		height: 75px;
	}
}
.wp-pagenavi span, .wp-pagenavi a {
	line-height:40px;
	width: 40px;
	display:inline-block;
	margin:0 5px;
	color:#838383;
	text-align:center;
	font-size: 16px;
	@include border-radius(6px);
	@include max-screen(768px) {
		display: none;
	}
}
.wp-pagenavi .extend,.wp-pagenavi a, .wp-pagenavi span.current {
	width:40px;
	height:40px;	
	background: #fff;
	color: #222;
}

.wp-pagenavi span.current,.wp-pagenavi a:hover {
	background: #3dcec5;
	color:#fff;
	opacity: 1;
}
.wp-pagenavi .first,
.wp-pagenavi .previouspostslink {
	position: relative;
	text-indent: -9999em;
	&:after {
		content: "";
		position: absolute;
		left: 19px;
		top: 50%;
		width: 12px;
		height: 12px;
		margin-top: -6px;
		border-left: 1px solid #222;
		border-top: 1px solid #222;
		-moz-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	&:before {
		content: "";
		position: absolute;
		left: 15px;
		top: 50%;
		width: 12px;
		height: 12px;
		margin-top: -6px;
		border-left: 1px solid #222;
		border-top: 1px solid #222;
		-moz-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	&.disable {background: #cccccc;pointer-events: none;}
	&.disable:after,&.disable:before,
	&:hover:after,&:hover:before {
		border-left: 1px solid #fff;
		border-top: 1px solid #fff;
	}
	@include max-screen(768px) {
		position: absolute;
		left: 0;
		top: 18px;
		display: inline-block;
		background: none!important;
		&:after,&:before {
		border-left: 1px solid #ccc;
		border-top: 1px solid #ccc;
		}
		&:hover:after,&:hover:before {
			border-left: 1px solid #ccc;
			border-top: 1px solid #ccc;
		}
	}
}
.wp-pagenavi .previouspostslink {
	&:after {display: none;}
	&:before {left: 16px;}
	@include max-screen(768px) {
		background: #b13634;
		&:before {left: 17px;}
	}
}
.wp-pagenavi .last,
.wp-pagenavi .nextpostslink {
	position: relative;
	text-indent: -9999em;
	&:after {
		content: "";
		position: absolute;
		left: 11px;
		top: 50%;
		width: 12px;
		height: 12px;
		margin-top: -6px;
		border-left: 1px solid #222;
		border-top: 1px solid #222;
		-moz-transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
		-ms-transform: rotate(135deg);
		transform: rotate(135deg);
	}
	&:before {
		content: "";
		position: absolute;
		left: 7px;
		top: 50%;
		width: 12px;
		height: 12px;
		margin-top: -6px;
		border-left: 1px solid #222;
		border-top: 1px solid #222;
		-moz-transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
		-ms-transform: rotate(135deg);
		transform: rotate(135deg);
	}
	&.disable {background: #cccccc;pointer-events: none;}
	&.disable:after,&.disable:before,
	&:hover:after,&:hover:before {
	border-left: 1px solid #fff;
	border-top: 1px solid #fff;
	}
	@include max-screen(768px) {
		position: absolute;
		right: 0;
		top: 18px;
		display: inline-block;
		background: none!important;
		&:after,&:before {
		border-left: 1px solid #ccc;
		border-top: 1px solid #ccc;
		}
		&:hover:after,&:hover:before {
			border-left: 1px solid #ccc;
			border-top: 1px solid #ccc;
		}
	}
}
.wp-pagenavi .extend {
	height: 40px;
	line-height: 40px;
}
.wp-pagenavi .nextpostslink {
	&:after {display: none;}
	&:before {left: 10px;}
	@include max-screen(768px) {
		background: #b13634;
		&:before {left: 11px;}
	}
}
.wp-pagenavi .pages {
	display: none;
	@include max-screen(768px) {
		display: block;
		width: 100px;
		position: absolute;
		left: 50%;
		top: 18px;
		margin-left: -50px;
		text-align: center;
		color: #ccc;
		font-size: 13px;
	}
}
.wp-pagenavi .first {
	@include max-screen(768px) {
		display: inline-block;
		text-indent: -9999em;
	}
}
.wp-pagenavi .last {
	@include max-screen(768px) {
		display: inline-block;
		text-indent: -9999em;
	}
}
/* end WordPress */

/*-----------------------------------------------------------
Body
-------------------------------------------------------------*/
body {
	width: 100%;
	color: $colortext;
	margin: 0;
	padding: 0;
	line-height: 1.6;
	letter-spacing: 0;
	font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-size: 12px;
	height: 100%;
	word-break: break-word;
	background: #e3edff;
}



/*-----------------------------------------------------------
 Container/Maincontent
------------------------------------------------------------*/
div.container {
	max-width: $widthcm;
	height: auto;
	padding: 0 15px;
	margin: 0 auto 0 auto;
}
div.mainContent {
	width: 700px;
	height: auto;
	margin: 0;
	float: right;
}

/*-----------------------------------------------------------
common
------------------------------------------------------------*/
.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.sp {
	
	@include min-screen(768px) {
		display: none!important;
	}
}

.pc {
	@include max-screen(767px) {
		display: none!important;
	}
}
img {
	max-width:100%;
	height:auto;
}

.contactBox {
	background:url(../../img/common/other/bg_orther.jpg);
	width:920px;
	height:199px;
	position:relative;
	margin:80px auto 0;
}
.contactBox a{
	position:absolute;
	top:123px;
	left:565px;
}

.mainImg {
	height:150px;
	background: $colorbg1 ;	
}
	.mainImg h2{
		line-height:150px;
		text-align:center;
		color: $colortxt1;
	}
#top {	
	.mainImg {
		height:507px;
		background: $colorbg1;	
	}
	.mainImg h2 {
		line-height:507px;
	}
}
.fixBtn {
	position:fixed;
	width:41px;
	right:0;
	top:50%;
	margin-top:-80px;	
}
img {
	max-width:100%;
}
.wcm {
	width: $widthcm;
	margin:auto;
}


.headBox {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3dcec5+0,1479b3+100 */
	background: #3dcec5; /* Old browsers */
	background: -moz-linear-gradient(45deg, #3dcec5 0%, #1479b3 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, #3dcec5 0%,#1479b3 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, #3dcec5 0%,#1479b3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3dcec5', endColorstr='#1479b3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.singlePage .headBox {
	background: none!important;
}
.bread {
	max-width: $widthcm;
	margin: 0 auto;
	color: #fff;
	text-align: left;
	padding: 15px;
	li {
		display: inline-block;
		font-size: 12px;
		color: #fff;
		line-height: 1;
		a {
			text-decoration: underline;
			color: #fff;
			padding-right: 30px;
			position: relative;
			&:after {
				content: "";
				position: absolute;
				right: 15px;
				top: 50%;
				width: 6px;
				height: 6px;
				margin-top: -3px;
				border-left: 1px solid #fff;
				border-top: 1px solid #fff;
				-moz-transform: rotate(135deg);
				-webkit-transform: rotate(135deg);
				-ms-transform: rotate(135deg);
				transform: rotate(135deg);
			}
			&:hover {
				text-decoration: none;
			}
		}
	}
	@include max-screen(767px) {
		background: #fff;
		padding: 8px 15px;
		li {
			font-size: 9px;
			color: #222;
			a {
				color: #222;
				padding-right: 16px;
				position: relative;
				&:after {
					right: 7px;
					width: 5px;
					height: 5px;
					margin-top: -3px;
					border-left: 1px solid #222;
					border-top: 1px solid #222;
				}
			}
		}
	}
}
.singlePage {
	.bread {
		color: #222;
		li {
			color: #222;
			a {
				color: #222;
				&:after {
					border-left: 1px solid #222;
					border-top: 1px solid #222;
				}
			}
		}
	}
}
.bHead {
	text-align: center;
	font-size: 32px;
	font-weight: bold;
	line-height: 1;
	padding: 40px 0 80px;
	color: #fff;
	@include max-screen(767px) {
		font-size: 20px;
		padding: 42px 0;
	}
}

























