@charset 'UTF-8';
.bl-hover {
	opacity: 0.6;
	transition: 0.3s all;
	-moz-transition: 0.3s all;
	-webkit-transition: 0.3s all;
	-ms-transition: 0.3s all;
}
.column {background: url(../img/common/other/img_bg.jpg) repeat;}
.column #wrap {
	width: 100%;
	height: auto;
	padding: 50px 20px 100px;
	main {
		max-width: 1000px;
		height: auto;
		margin: 0 auto;
		.mainLeft {
			width: calc(100% - 280px);
			float: left;
			h2 {
				font-size: 28px;
				color: #144cb3;
				margin-bottom: 20px;
				font-weight: bold;
			}
			.contentMainLeft {
				padding: 10px 30px 30px 30px;
				background: #fff;
				margin-bottom: 48px;
				border-bottom: solid 2px #bfc7d6;
				.contentBox {
					padding: 20px 0;
					border-bottom: solid 1px #e6e6e6;
					&:last-child {
						padding-bottom: 0;
						border-bottom: 0;
					}
					.featuredImage {
						width: 49%;
						float: left;
						img {
							display: block;
						}
					}
					.shortContent {
						width: 47%;
						float: right;
						margin-top: -4px;
						.time {
							font-size: 13px;
							color: #b3b3b3;
							margin-bottom: 5px;
							a {color: #b3b3b3;}
							a:hover {text-decoration: underline;}
						}
						.title {
							font-size: 20px;
							color: #222222;
							margin-bottom: 15px;
							line-height: 1.5;
							font-weight: bold;
							// max-height: 60px;
							// overflow: hidden;
							// display: -webkit-box;
							// -webkit-line-clamp: 2;
							// -webkit-box-orient: vertical;
							// text-overflow: ellipsis;
						}
						.desc {
							font-size: 13px;
							color: #b3b3b3;
							margin-bottom: 10px;
						}
						.readMore {
							display: inline-block;
							color: #fff;
							background: #3dcec5;
							text-align: center;
							position: relative;
							font-size: 13px;
							padding: 6px 42px;
							border-radius: 5px;
							-moz-border-radius: 5px;
							-webkit-border-radius: 5px;
							-ms-border-radius: 5px;
							&:after {
								position: absolute;
								content: '';
								right: 10px;
								top: 50%;
								width: 6px;
								height: 6px;
								margin-top: -3px;
								border-left: 2px solid #fff;
								border-top: 2px solid #fff;
								-moz-transform: rotate(135deg);
								-webkit-transform: rotate(135deg);
								-ms-transform: rotate(135deg);
								transform: rotate(135deg);
							}
							&:hover {
								opacity: 0.7;
							}
						}
					}
				}
			}
			.loadMore {
				display: block;
				width: 55%;
				margin: 0 auto 20px;
				border-radius: 5px;
				-moz-border-radius: 5px;
				-webkit-border-radius: 5px;
				-ms-border-radius: 5px;
				color: #fff;
				background: #3dcec5;
				border-bottom: solid 4px #37b2ab;
				text-align: center;
				padding: 14px 0;
				font-size: 20px;
				position: relative;
				&:hover {
					opacity: 0.7;
				}
				&:after {
					position: absolute;
					content: '';
					right: 20px;
					top: 50%;
					margin-top: -6px;
					width: 12px;
					height: 12px;
					border-left: 2px solid #fff;
					border-top: 2px solid #fff;
					-moz-transform: rotate(135deg);
					-webkit-transform: rotate(135deg);
					-ms-transform: rotate(135deg);
					transform: rotate(135deg);
				}
			}
			.adsSec {
				width: 86%;
				margin: 80px auto 0;
				p {
					float: left;
					width: 48.5%;
					&:last-child {
						float: right;
					}
					img {
						display: block;
					}
				}
			}
			.relatedArticles {
				padding: 10px 30px 30px;
				background: #fff;
				margin-bottom: 48px;
				border-bottom: solid 2px #bfc7d6;
				.listBox {
					padding: 20px 0;
					border-bottom: solid 1px #e6e6e6;
					&:last-child {
						border-bottom: 0;
						padding-bottom: 0;
					}
					.listBoxLeft {
						width: 26%;
						float: left;
						img {
							width: 100%;
							display: block;
							border: solid 1px #cdcdcd;
						}
					}
					.listBoxRight {
						width: 71%;
						float: right;
						font-size: 13px;
						color: #b3b3b3;
						.time {
							margin-bottom: 5px;
							a {
								color: #b3b3b3;
								&:hover {
									text-decoration: underline;
								}
							}
						}
						.titleBoxRight {
							font-size: 20px;
							font-weight: bold;
							margin-bottom: 12px;
							color: #222222;

							// height: 64px;
							// overflow: hidden;
							// display: -webkit-box;
							// -webkit-line-clamp: 2;
							// -webkit-box-orient: vertical;
							// text-overflow: ellipsis;
						}
						.descBoxRight {
							height: 20px;
							// overflow: hidden;
							// text-overflow: ellipsis;
							line-height: 20px;
							white-space: nowrap;
						}
					}
				}
				
			}

			@include max-screen(767px) {
				width: 100%;
				float: none;
				h2 {
					font-size: 15px;
					margin-bottom: 10px;
				}
				.contentMainLeft {
					padding: 0 10px 15px;
					border-bottom: solid 2px #bfc7d6;
					margin-bottom: 13px;
					.contentBox {
						&:first-child {
							padding-top: 10px;
						}
						padding: 15px 0;

						.featuredImage {
							width: 100%;
							float: none;
							img {
								width: 100%;
								display: block;
							}
						}
						.shortContent {
							width: 100%;
							float: none;
							margin-top: 15px;
							.time {
								font-size: 10px;
								margin-bottom: 5px;
							}
							.title {
								font-size: 16px;
								margin-bottom: 7px;
							}
							.desc {
								font-size: 12px;
							}
							.readMore {
								width: 100%;
								font-size: 14px;
								padding: 14px 0;
								font-weight: normal;
								&:after {
									right: 15px;
									width: 12px;
									height: 12px;
									margin-top: -6px;
									border-left: 2px solid #fff;
									border-top: 2px solid #fff;
								}
							}
						}
					}
				}
				.adsSec {
					margin: 25px 0;
					width: 100%;
					padding: 0 27.5px;
					p {
						float: none;
						width: 100%;
						img {
							width: 100%;							
						}
					}
				}
				.relatedArticles {
					padding: 0 10px 30px;
					margin-bottom: 23px;
					.listBox {
						padding: 10px 0;
						.listBoxLeft {
							width: 38%;
						}
						.listBoxRight {
							width: 58%;
							margin-top: -3px;
							font-size: 13px;
							.time {
								margin-bottom: 5px;
								font-size: 10px;
							}
							.titleBoxRight {
								font-size: 20px;
								margin-bottom: 12px;
								font-size: 13px;
								margin-bottom: 0;
								height: auto;
							}
						}
					}
					
				}
			}
		}
		.mainRight {
			width: 250px;
			float: right;
			.topBanner {
				width: 100%;
				height: auto;
				padding: 0;
				background: #144cb3;
				text-align: center;
				margin-bottom: 20px;
				p {
					a {
						display: block;
						&:hover {
							opacity: .8;
						}
					}
				}
				.bannerContent {
					width: 100%;
					height: auto;
					background: #fff;
					padding: 12px 10px 10px;
					position: relative;
					h4 {
						text-align: left;
						font-size: 24px;
						line-height: 1.3;
						color: #144cb3;
						font-weight: bold;
						margin-bottom: 10px;
						span {
							font-size: 28px;
							letter-spacing: -0.1px;
						}
					}
					a {
						display: block;
						font-size: 20px;
						font-weight: bold;
						padding: 15px 0;
						background: #3dcec5;
						color: #fff;
						border-radius: 5px;
						-moz-border-radius: 5px;
						-webkit-border-radius: 5px;
						-ms-border-radius: 5px;
						border-bottom: solid 5px #37b2ab;
						text-align: right;
						padding-right: 47px;
						position: relative;
						&:after {
							position: absolute;
							content: '';
							right: 12px;
							top: 50%;
							margin-top: -6px;
							width: 12px;
							height: 12px;
							border-left: 2px solid #fff;
							border-top: 2px solid #fff;
							-moz-transform: rotate(135deg);
							-webkit-transform: rotate(135deg);
							-ms-transform: rotate(135deg);
							transform: rotate(135deg);
						}
						&:hover {
							opacity: 0.7;
						}
					}
					.char {
						position: absolute;
						top: 70px;
						left: 22px;
						z-index: 1;
						img {
							width: 64px;
							height: auto;
						}
						
					}
				}
			}
			.sidebarList {
				margin-bottom: 18px;
				.titleList {
					text-align: center;
					color: #fff;
					font-size: 17px;
					width: 100%;
					padding: 16px 0;
					background: #144cb3;
					font-weight: bold;
					letter-spacing: 2px;
				}
				.listContent {
					background: #fff;
					padding: 0 10px;
					display: inline-block;
					.titleContent {
						background: #f14978;
						margin-top: 10px;
						color: #fff;
						font-size: 16px;
						padding: 5px 0;
						border-radius: 4px;
						-moz-border-radius: 4px;
						-webkit-border-radius: 4px;
						-ms-border-radius: 4px;
						text-align: center;
						display: block;
						font-weight: bold;
					}
					.titleContent2 {
						margin-bottom: 15px;
					}
					.listBox {
						padding: 15px 0 20px 0;
						border-bottom: solid 1px #cdcdcd;
						&:last-child {
							border-bottom: none;
						}
						.listBoxLeft {
							width: 30.5%;
							float: left;
							position: relative;
							img {
								width: 100%;
								display: block;
								border: solid 1px #cdcdcd;
							}
							.rankingNumber {
								position: absolute;
								top: 0;
								left: 0;
								color: #fff;
								width: 20px;
								height: 20px;
								text-align: center;
								font-family: "Arial";
								font-size: 14px;
							}
							.number1 {
								background: #d0b104;
							}
							.number2 {
								background: #a8a8a8;
							}
							.number3 {
								background: #a66f3d;
							}
							.number4 {
								background: #144cb3;
							}
							.number5 {
								background: #144cb3;
							}
						}
						.listBoxRight {
							width: 65%;
							float: right;
							margin-top: -4px;
							.titleBoxRight {
								font-size: 14px;
								color: #144cb3;
								margin-bottom: 2px;
								line-height: 1.4;
								font-weight: bold;

								// max-height: 60px;
								// overflow: hidden;
								// display: -webkit-box;
								// -webkit-line-clamp: 3;
								// -webkit-box-orient: vertical;
								// text-overflow: ellipsis;
							}
							.time {
								font-size: 10px;
								color: #b3b3b3;
								margin-bottom: 5px;
								a {color: #b3b3b3;}
							}
							.descBoxRight {
								font-size: 12px;
								color: #222222;
								line-height: 1.5;
							}
						}
					}
					.listBox2 {
						margin-bottom: 10px;
						border: solid 2px #144cb3;
						position: relative;
						&:after {
							position: absolute;
							content: '';
							right: 8px;
							top: 50%;
							width: 8px;
							height: 8px;
							border-left: 2px solid #fff;
							border-top: 2px solid #fff;
							-moz-transform: rotate(135deg);
							-webkit-transform: rotate(135deg);
							-ms-transform: rotate(135deg);
							transform: rotate(135deg);
						}
						&:before {
							position: absolute;
							content: '';
							height: 100%;
							width: 24px;
							background: #144cb3;
							right: 0
						}
						&:last-child {
							margin-bottom: 5px;
						}
						.listBox2Left {
							width: 26%;
							display: inline-block;
							vertical-align: middle;
							text-align: center;
						}
						.listBox2Right {
							padding: 13px 0;
							width: 74%;
							display: inline-block;
							vertical-align: middle;
							text-align: left;
							padding-right: 33px;
							.titleBoxRight {
								font-size: 18px;
								color: #144cb3;
								margin-bottom: 2px;
								line-height: 1.2;
								font-weight: bold;
							}
							.descBoxRight {
								font-size: 12px;
								color: #222222;
								line-height: 1.5;
							}
						}
					}
					.listBox3 {
						a:hover {
							opacity: 0.5;
							transition: 0.3s all;
							-moz-transition: 0.3s all;
							-webkit-transition: 0.3s all;
							-ms-transition: 0.3s all;
						}
						padding: 15px 0 10px;
						&:last-child {
							padding-bottom: 0;
						}
						ul {
							li {
								font-size: 14px;
								font-weight: bold;
								padding-bottom: 10px;
								border-bottom: solid 1px #cdcdcd;
								margin-bottom: 10px;
								&:first-child {
									ul {
										display: block;
									}
								}
								&:last-child {
									border-bottom: 0;
									margin-bottom: 0;
									padding-bottom: 0;
								}
								a {
									color: #144cb3;	
									width: 100%;
									display: block;
									position: relative;
									&:after {
										position: absolute;
										content: '';
										right: 0;
										top: 50%;
										width: 8px;
										height: 8px;
										margin-top: -4px;
										border-left: 2px solid #2257b7;
										border-top: 2px solid #2257b7;
										-moz-transform: rotate(225deg);
										-webkit-transform: rotate(225deg);
										-ms-transform: rotate(225deg);
										transform: rotate(225deg);
									}
								}
								ul {
									display: none;
									margin-left: 10px;
									margin-top: 5px;
									li {
										font-size: 12px;
										font-weight: normal;
										padding-bottom: 0;
										border-bottom: 0;
										margin-bottom: 0;
										a {
											&:after {
												content: none;
											}
										}
									}
								}
							}
						}
					}
				}
				.listContent2 {
					padding-bottom: 15px;
					border-bottom: solid 2px #caced6;
					width: 100%;
				}
			}
			@include max-screen(767px) {
				width: 100%;
				float: none;
				.topBanner {
					
					.bannerContent {
						padding: 20px 13px 13px;
						h4 {
							font-size: 16px;
							line-height: 1.5;
							text-align: right;
							margin-right: 27.5px;
							letter-spacing: 1px;
							margin-bottom: 15px;
						}
						a {
							text-align: center;
							font-size: 18px;
							padding: 16px 0;
							border-bottom: solid 3px #37b2ab;
							&:after {
								right: 18px;
								width: 14px;
								height: 14px;
								margin-top: -7px;
							}
						}
						.char {
							position: absolute;
							top: -7px;
							left: 24px;
							z-index: 0;
							img {
								width: 88.5px;
								height: auto;
							}
							
						}
					}
				}
				.titleListSp {
					font-size: 15px;
					font-weight: bold;
					margin-bottom: 10px;
					color: #144cb3;
				}
				.sidebarList {
					margin-bottom: 25px;
					.titleContentSp {
						font-size: 15px;
						font-weight: bold;
						margin-bottom: 5px;
						color: #144cb3;
					}
					.listContent {
						border-bottom: solid 1px #bfc7d6;
						margin-bottom: 25px;
						width: 100%;
						.listBox {
							padding: 10px 0;
							border-bottom: solid 1px #e6e6e6;
							.listBoxLeft {
								width: 38%;
								.rankingNumber {
									font-size: 14px;
									width: 25px;
									height: 25px;
									line-height: 25px;
								}
							}
							.listBoxRight {
								width: 58%;
								margin-top: -3px;
								.titleBoxRight {
									font-size: 16px;
								}
								.titleBoxRightSp {
									font-size: 13px;
									color: #222222;
								}
							}
						}
						.listBox2 {
							&:before {
								width: 33px;
							}
							&:after {
								width: 14px;
								height: 14px;
								margin-top: -7px;
								right: 12px;
							}
							.listBox2Left {
								width: 21%;
							}
							.listBox2Right {
								width: 79%;
								padding: 15px 54px 15px 0;
								.titleBoxRight {
									font-size: 19px;
									margin-bottom: 5px;
								}
							}
						}
						.listBox3 {
							&:first-child {
								padding-top: 0;
							}
						}
					}
					.listContent2 {
						padding: 10px;
						margin-bottom: 0;
					}
				}
			}
			@include max-screen(320px) {
				.bannerContent {
					h4 {
						font-size: 13px!important;
						margin-right: 20px!important;
					}
					.char {
						img {
							width: 77px!important;
						}
					}
				}
			}
		}
	}
	@include max-screen(767px) {
		padding: 20px 10px;
	}
}
.activeTimeLine {
	&:after {
		-moz-transform: rotate(45deg)!important;
		-webkit-transform: rotate(45deg)!important;
		-ms-transform: rotate(45deg)!important;
		transform: rotate(45deg)!important;
	}
}

.searchBox {
	width: 100%;
	padding: 30px 30px 50px;
	background: #fff;
	border-bottom: solid 2px #bfc7d6;
	.searchRow {
		margin-bottom: 3px; font-size: 0;
		p {
			display: inline-block;
			vertical-align: middle;
			width: 18%;
			padding: 24px 0 24px 10px;
			color: #222222;
			font-size: 15px;
			font-weight: bold;
			background: #d1e0df;
			border-radius: 5px;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			-ms-border-radius: 5px;
			margin-right: 2%;
			@include max-screen(1000px) {
				font-size: 9px;
			}
		}
		.searchContent {
			display: inline-block;
			vertical-align: middle;
			width: 80%;
			label {
				position: relative;
				color: #222222;
				background: #f5f5f5;
				border: solid 1px #e5e4e4;
				border-radius: 5px;
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				-ms-border-radius: 5px;
				text-align: center;
				font-size: 13px;
				font-weight: bold;
				height: 56px;
				line-height: 56px;
				width: 49%;
				display: inline-block;
				vertical-align: middle;
				cursor: pointer;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				margin-right: 2%;
				&:last-child {
					margin-right: 0;
				}
				@include max-screen(1000px) {
					font-size: 9px;
					line-height: 1.5;
					padding-top: 18px;
				}
			}
			.input-terminals {
				width: 32%;
				margin: 1% 2% 1% 0;
				&:last-child {
					margin: 1% 0 1% 0 !important;
				}
				&:nth-of-type(2n) {
					@include max-screen(767px) {
						margin: 1% 0 1% 0;
					}
				}
				&:nth-of-type(3n) {
					margin: 1% 0 1% 0;
					@include max-screen(767px) {
						margin: 1% 2% 1% 0;
					}
				}
				@include max-screen(767px) {
					width: 49%;
				}
			}
			label[for=rate1], label[for=rate2], label[for=rate3], label[for=rate4], label[for=rate5], label[for=delivery1], label[for=delivery2], label[for=delivery3], label[for=delivery4], label[for=delivery5] {
				width: 18%;
				margin-right: 2.5%;
				&:last-child {
					margin-right: 0;
				}
			}
			label[for=amount1], label[for=amount2], label[for=amount3]{
				width: 32%;
				margin-right: 2%;
				&:last-child {
					margin-right: 0;
				}
			}
			label[for=campaign1], label[for=campaign2], label[for=campaign3], label[for=campaign4] {
				width: 24%;
				margin-right: 1.3%;
				&:last-child {
					margin-right: 0;
				}
			}
			input {
				display: none;
			}
			input:checked + label {
				background: #3dcec5;
				border: solid 2px #9ee6e2;
				color: #fff;
				box-shadow: 2px 2px 5px #888888;
			}
		}
	}
	button {
		margin: 30px auto 0;
		display: block;
		width: 70%;
		padding: 18px 0;
		background: #3dcec5;
		color: #fff;
		font-size: 20px;
		font-weight: bold;
		border-bottom: solid 5px #37b2ab;
		text-align: center;
		border-radius: 8px;
		-moz-border-radius: 8px;
		-webkit-border-radius: 8px;
		-ms-border-radius: 8px;
		position: relative;
		&:after {
			position: absolute;
			content: '';
			right: 20px;
			top: 50%;
			margin-top: -6px;
			width: 12px;
			height: 12px;
			border-left: 2px solid #fff;
			border-top: 2px solid #fff;
			-moz-transform: rotate(135deg);
			-webkit-transform: rotate(135deg);
			-ms-transform: rotate(135deg);
			transform: rotate(135deg);
		}
		&:hover {
			opacity: 0.7;
			transition: 0.3s all;
			-moz-transition: 0.3s all;
			-webkit-transition: 0.3s all;
			-ms-transition: 0.3s all;
		}
	}

	@include max-screen(767px) {
		padding: 15px 10px 20px;
		border-bottom: none;
		.searchRow {
			margin-bottom: 3px; font-size: 0;
			p {
				display: block;
				width: 100%;
				padding: 0 0 0 10px;
				margin-bottom: 10px;
				color: #144cb3;
				font-size: 12px;
				background: none;
				border-radius: 0;
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				-ms-border-radius: 0;
				margin-right: 0;
				@include max-screen(1000px) {
					font-size: 12px;
				}
				position: relative;
				line-height: 1.3;
				&:after {
				    position: absolute;
				    content: '';
				    width: 3px;
				    height: 100%;
				    background: #144cb3;
				    @include border-radius(2px);
				    left: 0;
				    top: 0;
				}
			}
			.searchContent {
				display: block;
				width: 100%;
				margin-bottom: 15px;
				label {
					color: #222222;
					background: #f5f5f5;
					border: solid 1px #e5e4e4;
					font-size: 12px!important;
					font-weight: bold;
					height: 45px;
					line-height: 45px!important;
					padding-top: 0px !important;
					margin-right: 2%;
					text-align: left;
					position: relative;
					padding-left: 36px;
					&:after {
						position: absolute;
						content: '';
						top: 50%;
						margin-top: -7px;
						left: 10px;
						width: 16px;
						height: 16px;
						background: #fff;
						border: 1px solid #e5e4e4;
					}
					&:last-child {
						margin-right: 0;
					}
				}
				label[for=rate1], label[for=rate2], label[for=rate3], label[for=rate4], label[for=rate5], label[for=delivery1], label[for=delivery2], label[for=delivery3], label[for=delivery4], label[for=delivery5] {
					width: 49%;
					margin-right: 2%;
					margin-bottom: 6px;
				}
				label[for=rate2],label[for=rate4],label[for=delivery2], label[for=delivery4]{
					margin-right: 0;
				}
				label[for=amount1], label[for=amount2], label[for=amount3] {
					width: 100%;
					margin-right: 0;
					margin-bottom: 6px;
					&:nth-child(even) {
						margin-right: 0;
					}
				}
				label[for=campaign1], label[for=campaign2], label[for=campaign3], label[for=campaign4] {
					width: 49%;
					margin-right: 2%;
					margin-bottom: 6px;
				}
				label[for=campaign2], label[for=campaign4] {
					margin-right: 0;
				}
				input {
					display: none;
				}
				input:checked + label {
					background: #3dcec5;
					border: solid 2px #9ee6e2;
					color: #fff;
					box-shadow: 2px 2px 5px #888888;
				}
			}
		}
		button {
			margin: 30px auto 0;
			display: block;
			width: 100%;
			padding: 13px 0;
			background: #144cb3;
			color: #fff;
			font-size: 14px;
			font-weight: bold;
			border-bottom: none;
			text-align: center;
			border-radius: 5px;
			-moz-border-radius: 5px;
			-webkit-border-radius: 5px;
			-ms-border-radius: 5px;
			position: relative;
			&:after {
				position: absolute;
				content: '';
				right: 20px;
				top: 50%;
				margin-top: -6px;
				width: 12px;
				height: 12px;
				border-left: 2px solid #fff;
				border-top: 2px solid #fff;
				-moz-transform: rotate(135deg);
				-webkit-transform: rotate(135deg);
				-ms-transform: rotate(135deg);
				transform: rotate(135deg);
			}
			&:hover {
				opacity: 0.7;
				transition: 0.3s all;
				-moz-transition: 0.3s all;
				-webkit-transition: 0.3s all;
				-ms-transition: 0.3s all;
			}
		}
	}

}


.singlePage {
	#wrap {
		padding-top: 0;
	}
	@include max-screen(767px) {
		#wrap {
			padding-top: 10px;
		}
	}
}

.social_block {margin: 20px 15px 0;
	ul {margin-left: -5px; margin-right: -5px;
		li {float: left; width: 50%; padding: 0 5px; margin-top: 10px;
			a {display: block;
				img {display: block; width: 100%;}
			}
		}
	}
}




.goToButton {
	margin: 25px auto 0;
	display: block;
	width: 70%;
	padding: 15px 0;
	background: #f14978;
	color: #fff!important;
	text-decoration: none!important;
	font-size: 20px;
	font-weight: bold;
	border: none;
	box-shadow: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-bottom: solid 5px #c02055;
	text-align: center;
	border-radius: 8px;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
	-ms-border-radius: 8px;
	position: relative;
	&:after {
		position: absolute;
		content: '';
		right: 20px;
		top: 50%;
		margin-top: -6px;
		width: 12px;
		height: 12px;
		border-left: 2px solid #fff;
		border-top: 2px solid #fff;
		-moz-transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
		-ms-transform: rotate(135deg);
		transform: rotate(135deg);
	}
	&:hover {
		opacity: 0.7;
	}
	@include max-screen(767px) {
		width: 100%;
		font-size: 14px;
		margin-top: 15px;
		border-bottom: solid 3px #c02055;
	}

	&.blue {
		background: #3dcec5;
		border-bottom: solid 5px #36b1ab;
		@include max-screen(767px) {
			border-bottom: solid 3px #36b1ab;
		}
	}
}



.titleSecLeft {
	font-size: 28px;
	font-weight: bold;
	color: #144cb3;
	margin-bottom: 15px;
	@include max-screen(767px) {
		font-size: 15px;
		margin-bottom: 5px;
	}
}





// DETAIL.PHP
.detailMainLeft {
	padding: 30px;
	background: #fff;
	margin-bottom: 48px;
	border-bottom: solid 2px #bfc7d6;
	.blockContent1 {
		line-height: 1.75;
		margin-top: 20px;
		a {
			color: #144cb3;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.date {
		padding-bottom: 14px;
		line-height: 1;
		color: #222222;
		font-size: 12px;
		em {display: inline-block;vertical-align: middle;font-style: normal;}
		a {
			display: inline-block;
			vertical-align: middle;
			color: #222;
			padding-left: 10px;
			margin-left: 10px;
			position: relative;
			&:hover {
				text-decoration: underline;
			}
			&:after {
				position: absolute;
				top: 0;
				left: 0;
				content: '';
				width: 1px;
				height: 12px;
				background: #222;
			}
		}
		@include max-screen(767px) {
			margin: 0px;
			padding-bottom: 4px;
			font-size: 10px;
			a {
				&:after {
					top: -1px;
					height: 10px;
				}
			}
		}
	}
	p {
		font-size: 15px;
		color: #222222;
	}
	.titleDetail {
		font-weight: bold;
		font-size: 26px;
		color: #222222;
		padding: 18px 0;
		margin: 0;
		line-height: 1.4;
		border-bottom: dotted 2px #144cb3;
		
		position: relative;
		&:after {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 5px;
			background: #144cb3;
			@include border-radius(3px);
		}
		@include max-screen(767px) {
			border: none;
			&:after {
				display: none;
			}
		}
	}
	.mainBanner {
		width: 100%;
		margin: 20px 0 0;
		img {
			width: 100%;
			display: block;
		}
	}
	.accordinBox {
		padding: 25px 28px 20px;
		background: #f5f5f5;
		border: solid 1px #cdcdcd;
		width: 76%;
		margin-top: 24px;
		p {
			font-size: 18px;
			color: #222222;
			margin-bottom: 13px;
			font-weight: bold;
		}
		ul {
			li {
				margin-bottom: 7px;
				position: relative;
				padding-left: 20px;
				letter-spacing: 0.2px;
				a {
					color: #144cb3;
					font-size: 16px;
					font-weight: bold;
					padding-bottom: 1px;
					border-bottom: solid 1px #144cb3;
					display: inline;
					&:hover{
						border-bottom: none;
					}
				}
				&:before {
					position: absolute;
					content: '';
					width: 14px;
					height: 14px;
					border-radius: 50%;
					background: #144cb3;
					top: 4px;
					left: 0;
					
				}

				
			}
		}
	}
	.blockContent {
		a:hover {
			text-decoration: none!important;
		}
		margin-top: 50px;
		width: 100%;
		font-size: 15px;
		line-height: 1.75;
		letter-spacing: 1px;
		h3 {
			font-size: 20px;
			color: #fff;
			font-weight: bold;
			padding: 15px 20px;
			background: #144cb3;
			border-radius: 5px;
			-moz-border-radius: 5px;
			-webkit-border-radius: 5px;
			-ms-border-radius: 5px;
			line-height: 1.5;
		}
		h4 {
			font-size: 18px;
			font-weight: bold;
			color: #144cb3;
			position: relative;
			padding-left: 20px;
			margin-top: 30px;
			&:before {
				position: absolute;
				content: '';
				width: 5px;
				height: 100%;
				background: #144cb3;
				border-radius: 5px;
				left: 0;
				top: 0;
			}
		}
		p {
			margin-top: 15px;
			a {
				text-decoration: underline;
				color: #144cb3;
			}
		}
		table {
			margin-top: 25px;
			border: solid 1px #cdcdcd;
			height: auto; 
			width: auto;
			tbody {
				tr {
					td {
						text-align: center;
						vertical-align: middle;
						border-right: solid 1px #cdcdcd;
						border-bottom: solid 1px #cdcdcd;
						font-size: 20px;
						color: #f36b8e;
						font-weight: bold;
						padding: 12px 20px;
						ul {
							li {
								position: relative;
								text-align: left;
								color: #f14978;
								font-weight: bold;
								font-size: 15px;
								padding-left: 18px;
								&:before {
									position: absolute;
								    content: '';
								    width: 10px;
								    height: 10px;
								    border-radius: 50%;
								    background: #f14978;
								    top: 8px;
								    left: 0;
									@include max-screen(767px) {
										top: 5px;
									}
								}
							}
						}
					}
					&:first-child {
						td {
							font-size: 15px;
							color: #222222;
							font-weight: normal;
							background: #f0f0f0;
							padding: 7px 30px;
							font-weight: bold;
						}
					}
				}
			}
		}
		h5 {
			font-weight: bold;
			font-size: 18px;
			position: relative;
			padding-left: 20px;
			margin-top: 25px;
			&:before {
				position: absolute;
				content: '';
				width: 14px;
				height: 14px;
				background: #144cb3;
				top: 2px;
				left: 0;
			}
		}
		h6 {
			font-weight: bold;
			color: #222222;
			font-size: 15px;
			margin: 25px 0 -10px;
		}
		ol {
			li {
				list-style-type: decimal;
				list-style-position: inside;
			}
		}
		.postSameCat {
			width: 100%;
			border: solid 1px #cdcdcd;
			padding: 20px 20px 21px;
			margin-top: 25px;
			.featuredImage {
				width: 27.5%;
				float: left;
				margin-top: 0;
				img {
					width: 100%;
					height: 100%;
					display: block;
				}
			}
			.shortDesc {
				margin-top: -4px;
				width: 68.3%;
				float: right;
				p {margin-top: 0; font-size: 13px; color: #b3b3b3;}
				p.titleRelative {max-height: 64px; overflow: hidden; font-size: 20px; font-weight: bold; color: #222222; margin-bottom: 11px; line-height: 1.6;}
				p.contentRelative {
					height: 22px; white-space: nowrap;
					// text-overflow: ellipsis; overflow: hidden;
				}
				a {color: #444; text-decoration: none;}
				a.category {color: #b3b3b3;
					&:hover {text-decoration: underline;}
				}
			}
		}
		.reviewBox {
			width: 100%;
			padding: 16px 4px 4px;
			border-radius: 5px;
			-moz-border-radius: 5px;
			-webkit-border-radius: 5px;
			-ms-border-radius: 5px;
			background:  #3dcec5;
			margin-top: 20px;
			.rateBox {
				margin: 0 0 15px 15px;
				.rateBoxLeft {
					display: inline-block;
					vertical-align: middle;
					margin-right: 15px;
					margin-top: 0;
					img {
						display: block;
					}
				}
				.rateBoxRight {
					display: inline-block;
					vertical-align: middle;
					p {
						font-size: 15px;
						color: #fff;
						margin-top: 0;
						font-weight: bold;
						strong {
							color: #fac917;
						}
					}
				}
			}
			.reviewContent {
				width: 100%;
				height: 100%;
				background: #fff;
				padding: 20px;
				p {
					margin-top: 0;
					margin-bottom: 30px;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.bannerDetail {
		width: 100%;
		border-radius: 5px;
		-moz-border-radius: 5px;
		-webkit-border-radius: 5px;
		-ms-border-radius: 5px;
		background: #144cb3;
		padding: 15px 4px 4px;
		margin-top: 50px;
		p {
			font-size: 23px;
			font-weight: bold;
			text-align: center;
			color: #fff;
			margin-bottom: 10px;
		}
		.bannerContent {
			width: 100%;
			height: 100%;
			background: #fff;
			position: relative;
			padding-bottom: 25px;
			.char {
				position: absolute;
				bottom: -20px;
				left: 5.2%;
			}
			h4 {
				text-align: left;
				font-size: 18px;
				font-weight: bold;
				color: #144cb3;
				padding-top: 24px;
				margin-left: 28%;
				line-height: 1.5;
				margin-bottom: 5px;
			}
			a {
				display: block;
				width: 65%;
				padding: 15px 0;
				background: #3dcec5;
				color: #fff;
				font-size: 20px;
				font-weight: bold;
				border-bottom: solid 5px #37b2ab;
				text-align: center;
				border-radius: 8px;
				-moz-border-radius: 8px;
				-webkit-border-radius: 8px;
				-ms-border-radius: 8px;
				position: relative;
				margin-left: 28%;
				
				&:after {
					position: absolute;
					content: '';
					right: 20px;
					top: 50%;
					margin-top: -6px;
					width: 12px;
					height: 12px;
					border-left: 2px solid #fff;
					border-top: 2px solid #fff;
					-moz-transform: rotate(135deg);
					-webkit-transform: rotate(135deg);
					-ms-transform: rotate(135deg);
					transform: rotate(135deg);
				}
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
	.adsSecDetail {
		margin: 40px auto 0;
		width: 95%;
	}


	// DETAIL.PHP
	@include max-screen(767px) {
		padding: 15px 10px 30px;
		margin-bottom: 23px;
		.timeDetail {
			font-size: 10px;
			margin-top: 0!important;
		}
		.titleDetail {
			padding: 8px 0 5px;
			font-size: 16px;
		}
		.mainBanner {
			margin-bottom: 15px;
			margin-top: 6px;
		}
		p {
			font-size: 12px;
			margin-top: 10px;
		}
		.accordinBox {
			width: 100%;
			padding: 15px;
			margin-top: 15px;
			p {
				font-size: 13px;
				margin-bottom: 5px;
				margin-top: 0;
			}
			ul {
				li {
					&:before {
						width: 10px;
						height: 10px;
					}
					a {
						font-size: 12px;
					}
				}
			}
		}
		.blockContent {
			margin-top: 30px;
			h3 {
				font-size: 15px;
				border-radius: 4px;
				-webkit-border-radius: 4px;
				-ms-border-radius: 4px;
				-moz-border-radius: 4px;
				padding: 12px 15px 10px;
				line-height: 1.4;
			}
			table {
				width: 100%!important;
				margin-top: 15px;
				height: 71px;
				tbody {
					tr {
						&:first-child {
							td {
								font-size: 12px;
								height: 30px;
								padding: 7px;
							}
						}
						th {
							font-size: 13px;
						}
						td {
							font-size: 15px;
							height: auto;
							padding: 7px 12px;
							span {
								font-size: 12px!important;
							}
							ul {
								li {
									font-size: 12px;
									padding-left: 13px;
								}
							}
						}
					}
				}
			}
			h4 {
				font-size: 13px;
				margin-top: 15px;
				padding-left: 10px;
				&:before {
					width: 3px;
					top: -1px;
				}
			}
			h5 {
				font-size: 13px;
				padding-left: 13px;
				margin-top: 15px;
				&:before {
					top: 1px;
					width: 10px;
					height: 10px;
					margin-top: 0;
				}
			}
			h6 {
				margin-top: 15px;
				font-size: 13px;
			}
			.postSameCat {
				padding: 10px;
				margin-top: 15px;
				.featuredImage {
					margin-top: 0!important;
					width:38%;
				}
				.shortDesc {
					width: 58%;
					p {
						margin-top: 0!important;
						font-size: 10px;
						&:nth-child(2) {
							font-size: 13px;
							margin-bottom: 0;
						}
						&:nth-child(3) {
							display: none;
						}
					}
				}
			}
			.reviewBox {
				padding: 10px 3px 3px;
				.rateBox {
					margin: 0 10px 10px 10px;
					.rateBoxLeft {
						margin-top: 0!important;
						margin-right: 10px;
						img {
							width: 29px;
							height: auto;
						}
					}
					.rateBoxRight {
						max-width: calc(100% - 39px);
						p {
							margin-top: 0!important;
							font-size: 12px!important;
						}
					}
				}
				.reviewContent {
					padding: 10px;
					p {
						margin-top: 0!important;
						margin-bottom: 15px;
					}
				}
			}
		}
		.goToButton {
			width: 100%;
			font-size: 18px;
			margin-top: 15px;
			border-bottom: solid 3px #c02055;
		}
		.adsSecDetail {
			margin-top: 30px;
			width: 100%;
			padding: 0 12.5px;
			p {
				margin-top: 0!important;
			}
		}
	}

}