$color_pink : #f14978;
$color_border : #cdcdcd;
#ranking .sArchive {display: none;}
.rankBlock {
	background: #fff;
	padding: 10px 10px 45px;
	border-bottom: 1px solid #bfc7d6;
	@include max-screen(767px) {
		padding: 5px 5px 30px;
	}
	.btnMore {
		text-align: center;
		font-weight: bold;
		font-size: 24px;
		margin-top: 40px;
		a {
			color: #144cb3;
			display: inline-block;
			border-bottom: 1px solid #144cb3;
			padding-bottom: 3px;
			line-height: 1;
			&:hover {
				border-bottom: 1px solid #fff;
			}
		}
		@include max-screen(767px) {
			font-size: 15px;
			margin-top: 20px;
		}
	}
}
.rankInfo {
	border: 5px solid #144cb3;
	background: #fff;
	@include border-radius(5px);
	padding: 11px 19px 40px;
	margin-bottom: 15px;
	position: relative;
	display: none;
	@include max-screen(767px) {
		border: 3px solid #144cb3;
		padding: 4px 8px 15px;
		margin-bottom: 5px;
		position: relative;
	}
	.num {
		display: inline-block;
		position: absolute;
		z-index: 10;
		top: -5px;
		left: -5px;
		width: 60px;
		height: 60px;
		background: #144cb3;
		color: #fff;
		font-size: 30px;
		text-align: center;
		font-family: 'arial';
		font-weight: bold;
		line-height: 60px;
		@include border-radius(5px 0 5px 0);
		@include max-screen(767px) {
			top: -3px;
			left: -3px;
			width: 40px;
			height: 40px;
			font-size: 23px;
			line-height: 40px;
		}
	}
	h3 {
		font-weight: bold;
		font-size: 26px;
		padding-left: 55px;
		line-height: 1.4;
		margin-bottom: 25px;
		@include max-screen(767px) {
			font-size: 20px;
			padding-left: 43px;
			margin-bottom: 25px;
		}
	}
	.desc {
		font-size: 15px;
		margin: 10px 0 25px;
		line-height: 1.8;
		@include max-screen(767px) {
			font-size: 12px;
			margin: 12px 0;
		}
	}

	table {
		margin: 0 auto 15px;
		border: solid 1px $color_border;
		height: auto; 
		width: auto;
		tr {
			td {
				text-align: center;
				vertical-align: middle;
				border-right: solid 1px $color_border;
				border-bottom: solid 1px $color_border;
				font-size: 15px;
				padding: 12px 20px;
				strong {color: #f36b8e; font-size: 20px;}
				.campaign-text {
					font-size: 20px;
					color: #f36b8e;
					font-weight: bold;
					@include max-screen(767px) {
						font-size: 15px;
					}
					span {
						font-size: 14px;
						@include max-screen(767px) {
							font-size: 12px;
						}
					}
				}
				ul {
					li {
						position: relative;
						text-align: left;
						color: #f14978;
						font-weight: bold;
						font-size: 15px;
						padding-left: 18px;
						&:before {
							position: absolute;
						    content: '';
						    width: 10px;
						    height: 10px;
						    border-radius: 50%;
						    background: #f14978;
						    top: 8px;
						    left: 0;
							@include max-screen(767px) {
								top: 5px;
							}
						}
					}
				}
			}
			&:first-child {
				td {
					font-size: 15px;
					color: #222222;
					font-weight: normal;
					background: #f0f0f0;
					padding: 7px 30px;
					font-weight: bold;
				}
			}
		}
		@include max-screen(767px) {
			width: 100%!important;
			margin-bottom: -1px;
			tr {
				&:first-child {
					td {
						font-size: 12px;
						padding: 7px;
					}
				}
				th {
					font-size: 13px;
				}
				td {
					font-size: 12px;
					height: auto;
					padding: 7px 12px;
					width: 50%;
					strong {font-size: 15px;}
					span {
						font-size: 12px!important;
					}
					ul {
						li {
							font-size: 12px;
							padding-left: 13px;
						}
					}
				}
			}
		}
	}
	.rateBox {
		.thumb {
			float: left;
			width: 43%;
			border: 1px solid $color_border;
			img {display: block;}
		}
		.rateInner {
			float: right;
			width: 54.5%;
			.point {
				span {
					display: inline-block;
					vertical-align: middle;
					line-height: 36px;
					color: #fff;
					background: #144cb3;
					font-weight: bold;
					font-size: 15px;
					padding: 0 10px;
					margin-right: 10px;
				}
				.star {
					display: inline-block;
					vertical-align: middle;
					background: url(../img/ranking/bg_star.png) no-repeat left center;
					overflow: hidden;
					width: 176px;
					height: 36px;
					margin-right: 15px;
					em {
						width: 100%;
					}
					&.star5 {width: 176px;}
					&.star45 {width: 158.4px;}
					&.star4 {width: 140.8px;}
					&.star35 {width: 123.2px;}
					&.star3 {width: 105.6px;}
					&.star25 {width: 88px;}
					&.star2 {width: 70.4px;}
					&.star15 {width: 52.8px;}
					&.star1 {width: 35.2px;}
				}
				strong {
					font-size: 30px;
					color: $color_pink;
					display: inline-block;
					vertical-align: middle;
					line-height: 1;
				}
			}
			
			ul {
				margin-top: 7px;
				background: #f0f0f0;
				padding: 8px 14px;
				li {
					font-size: 15px;
					span {
						min-width: 100px;
						display: inline-block;
						vertical-align: middle;
					}
					em {
						background: url(../img/ranking/s_star.png) no-repeat left center;
						width: 79px;
						height: 14px;
						display: inline-block;
					}
				}
			}
		}
		@include max-screen(767px) {
			.rateInner {
				.point {
					span {
						line-height: 1;
						font-size: 12px;
						padding: 0 0 2px;
						margin-left: -5px;
						margin-right: 0;
						position: relative;
						display: block;
						background: none;
						color: #222;
						i {font-style: normal;}
					}
					.star {
						background: url(../img/ranking/bg_star.png) no-repeat left center;
						background-size: 88px auto;
						width: 88px;
						height:15px;
						margin-right: 10px;
						&.star5 {width: 88px;}
						&.star45 {width: 79.2px;}
						&.star4 {width: 70.4px;}
						&.star35 {width: 61.6px;}
						&.star3 {width: 52.8px;}
						&.star25 {width: 44px;}
						&.star2 {width: 35.2px;}
						&.star15 {width: 26.4px;}
						&.star1 {width: 17.6px;}
					}
					strong {
						font-size: 15px;
					}
				}
				ul {
					margin-top: 4px;
					padding: 4px 10px;
					li {
						font-size: 10px;
						line-height: 1.3;
						span {
							min-width: 60px;
						}
						em {
							margin-right: 5px;
							background: url(../img/ranking/s_star_sp.png) no-repeat left center;
							width: 56px;
							height: 10px;
							display: inline-block;
						}
					}
				}
			}
		}
		@include max-screen(320px) {
			.rateInner {
				ul {
					padding: 4px;
					li {
						font-size: 8px;
						span {
							min-width: 50px;
						}
					}
				}
			}
		}
	}
	&.rankInfo1 {border: 5px solid #d0b104;
		.num {background: #d0b104;}
		@include max-screen(767px) {
			border: 3px solid #d0b104;
		}
	}
	&.rankInfo2 {border: 5px solid #a8a8a8;
		.num {background: #a8a8a8;}
		@include max-screen(767px) {
			border: 3px solid #a8a8a8;
		}
	}
	&.rankInfo3 {border: 5px solid #a66f3d;
		.num {background: #a66f3d;}
		@include max-screen(767px) {
			border: 3px solid #a66f3d;
		}
	}
}

.boardBox {
	background: url(../img/ranking/bg_cornor_tl.jpg) no-repeat left top, url(../img/ranking/bg_cornor_tr.jpg) no-repeat right top,url(../img/ranking/bg_cornor_rb.jpg) no-repeat right bottom,url(../img/ranking/bg_cornor_lb.jpg) no-repeat left bottom,url(../img/ranking/bg_line_t.jpg) repeat-x center top,url(../img/ranking/bg_line_b.jpg) repeat-x center bottom,url(../img/ranking/bg_line_l.jpg) repeat-y left center,url(../img/ranking/bg_line_r.jpg) repeat-y right center,url(../img/ranking/bg_board.jpg) no-repeat center bottom 12px;
	background-color: #246e3a;
	padding: 64px 20px 68px;
	color: #fff;
	position: relative;
	margin-bottom: 42px;
	.charaMan {
		position: absolute;
		bottom: -42px;
		right: 8px;
		width: 21%;
		max-width: 145px;
		height: auto;
		z-index: 20;
		img {
			display: block;
		}
		@include max-screen(992px) {
			width: 17%;
		}
	}
	.charaWomen {
		position: absolute;
		bottom: -42px;
		left: 10px;
		width: 19%;
		max-width: 136px;
		height: auto;
		z-index: 20;
		img {
			display: block;
		}
		@include max-screen(992px) {
			width: 15%;
		}
		
	}
	h3 {
		font-size: 34px;
		font-weight: bold;
		text-align: center;
		margin-bottom: 17px;
	}
	p {
		max-width: 460px;
		padding: 0 15px;
		margin: 0 auto;
		font-size: 15px;
		line-height: 1.8;
	}
	@include max-screen(767px) {
		background: url(../img/ranking/bg_cornor_tl.jpg) no-repeat left top, url(../img/ranking/bg_cornor_tr.jpg) no-repeat right top,url(../img/ranking/bg_cornor_rb.jpg) no-repeat right bottom,url(../img/ranking/bg_cornor_lb.jpg) no-repeat left bottom,url(../img/ranking/bg_line_t.jpg) repeat-x center top,url(../img/ranking/bg_line_b.jpg) repeat-x center bottom,url(../img/ranking/bg_line_l.jpg) repeat-y left center,url(../img/ranking/bg_line_r.jpg) repeat-y right center,url(../img/ranking/bg_board.jpg) no-repeat center bottom 8px;
		background-size: 20px auto, 20px auto, 20px auto, 20px auto,20px auto, auto 8px, 20px auto, 20px auto, 86px auto ;
		background-color: #246e3a;
		padding: 30px 0 35px;
		margin-bottom: 15px;
		.charaMan {
			bottom: -15px;
			right: -6px;
			width: 20.4%;
			max-width: 85px;
		}
		.charaWomen {
			position: absolute;
			bottom: -15px;
			left: -4px;
			width: 19.2%;
			max-width: 80px;
		}
		h3 {
			font-size: 20px;
			margin-bottom: 10px;
		}
		p {
			max-width: 66%;
			padding: 0;
			font-size: 12px;
		}
	}
}