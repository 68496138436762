@charset 'UTF-8';




/*-----------------------------------------------------------
Header
-------------------------------------------------------------*/

body {
	padding-top: 131px;
	@include max-screen(767px) {
		padding-top: 65px;
	}
	&.showMenu {
		.header {
			&__closeBtn {
				span {
					width: 112%;
					&:nth-child(1) {
						@include transform_c(rotate(135deg));
						left: 35px;
						top: 1px;
					}
					&:nth-child(2) {
						width: 0;
						left: 17px;
					}
					&:nth-child(3) {
						@include transform_c(rotate(-135deg));
						left: 35px;
					}
				}
			}
			&__gnavi {
				height: calc(100vh - 65px);
				padding-bottom: 100px;
				>li.parent {
					ul {}
				}
			}
		}
	}
}

.header {
	position: relative;
	z-index: 600;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 131px;
	@include max-screen(767px) {
		height: 65px;
	} // &:before {content: ""; display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: url(../img/common.jpg) center top no-repeat;}
	// > * {opacity: 0.5;}
	&__top {
		background: #144cb3;
		color: #fff;
		@include max-screen(767px) {
			padding: 13px 0;
			height: 65px;
		}
		.h1 {
			display: inline-block;
			vertical-align: middle;
			max-width: calc(100% - 198px);
			margin: 0;
			@include max-screen(767px) {
				max-width: calc(100% - 40px);
				display: block;
				margin-top: 7px;
			}
			h1 {
				font-size: 12px;
				font-weight: normal;
				line-height: 1.5;
				margin: 0;
				display: block;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
				overflow: hidden;
				max-height: 36px;
				@include max-screen(767px) {
					line-height: 1;
					height: 10px;
					font-size: 10px;
					max-height: 10px;
				}
			}
		}
		.logoWimax {
			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;
			padding: 17px 0;
			@include max-screen(767px) {
				padding: 0;
			}
			a {
				display: block;
				img {
					display: block;
					height: 26px;
					width: 183px;
					@include max-screen(767px) {
						height: 18px;
						width: 127px;
					}
				}
				&:hover {
					opacity: 0.8;
				}
			}
		}
		&__left {
			float: left;
			width: calc(100% - 320px);
			font-size: 0;
			white-space: nowrap;
			@include max-screen(767px) {
				float: none;
				width: auto;
			}
		}
		&__right {
			float: right;
			padding-top: 20px;
			@include max-screen(767px) {
				display: none;
			}
			a {
				display: inline-block;
				vertical-align: top;
				height: 30px;
			}
			#iframeFacebook {
				display: inline-block;
				vertical-align: top;
				height: 30px;
			}
		}
	}
	&__closeBtn {
		position: absolute;
		display: block;
		top: 0;
		right: 0;
		width: 65px;
		height: 65px;
		padding: 19px 15px;
		span {
			display: block;
			width: 100%;
			height: 4px;
			background: #fff;
			border-radius: 10px;
			margin-bottom: 7px;
			transition: 0.3s ease all;
			position: relative;
			left: 0;
			&:nth-child(1) {
				transform-origin: left top;
			}
			&:nth-child(2) {}
			&:nth-child(3) {
				margin-bottom: 0;
				transform-origin: left bottom;
			}
		}
	}
	&__gnavi {
		border-bottom: 1px solid #33ada5;
		height: 71px;
		background: #fff;
		&__master {
			font-size: 0;
			padding: 10px 0;
			>li {
				display: inline-block;
				font-size: 12px;
				font-weight: bold;
				width: 25%;
				border-right: 1px solid #cdcdcd;
				position: relative;
				@include max-screen(1000px) {
					font-size: 9px;
				}
				&:first-child {
					border-left: 1px solid #cdcdcd;
				}
				&:before {
					background: #144cb3;
					height: 6px;
					width: 100%;
					position: absolute;
					bottom: -10px;
					left: 0;
					content: "";
					opacity: 0;
					@include transition_c(0.3s ease all);
				}
				a {
					display: block;
					line-height: 50px;
					text-align: center;
					i {
						display: inline-block;
						vertical-align: middle;
						width: 38px;
						height: 38px;
						background: url(../img/common/header/icon.png) center no-repeat;
						border: 1px solid #144cb3;
						border-radius: 100px;
						margin-right: 11px;
						@include max-screen(1000px) {
							margin-right: 5px;
						}
					}
					&:hover {
						color: #444;
					}
				}
				&#parent01 a i {
					background-position: 7px center;
				}
				&#parent02 a i {
					background-position: -239px center;
				}
				&#parent03 a i {
					background-position: -473px center;
				}
				&#parent04 a i {
					background-position: -745px center;
				}
				&.parent {
					ul {
						position: absolute;
						top: 100%;
						left: 0;
						width: 100%;
						padding: 20px 10px;
						display: none;
						li {
							border-left: 1px solid #f5f5f5;
							border-right: 1px solid #f5f5f5;
							background: #fff;
							a {
								//white-space: nowrap;
								line-height: 40px;
								background: #fff;
								padding: 0 8px;
								font-size: 13px;
								color: #222222;
								text-align: left;
								font-weight: normal;
								span {
									border-top: 1px solid #cdcdcd;
									position: relative;
									display: block;
									&:after {
										content: "";
										display: block;
										width: 5px;
										height: 5px;
										border-top: 1px solid #222222;
										border-right: 1px solid #222222;
										@include transform_c(rotate(45deg));
										position: absolute;
										top: 50%;
										right: 5px;
										margin-top: -3px;
									}
								}
							}
							&:first-child {
								border-radius: 10px 10px 0 0;
								border-top: 1px solid #f5f5f5;
								a {
									border-radius: 9px 9px 0 0;
									position: relative;
									span {
										border-top: 0;
									}
									&:before {
										content: "";
										display: block;
										width: 16px;
										height: 14px;
										position: absolute;
										top: -7px;
										left: 50%;
										border-top: 1px solid #f5f5f5;
										border-left: 1px solid #f5f5f5;
										transform: rotate(60deg) skew(30deg);
										background: #fff;
										margin-left: -5px;
										@include transition_c(0.3s ease all);
									}
								}
								&:hover {
									a {
										&:before {
											background: #3dcec5;
										}
									}
								}
							}
							&:last-child {
								border-bottom: 1px solid #f5f5f5;
								border-radius: 0 0 10px 10px;
								box-shadow: 0 2px rgba(0, 0, 0, 0.2);
								a {
									border-radius: 0 0 9px 9px;
								}
								@include max-screen(1000px) {
									border-radius: 0;
									border-bottom: 0;
									box-shadow: none;
									a {
										border-radius: 0;
									}
								}
							}
							&:first-child:last-child {
								border-bottom: 1px solid #f5f5f5;
								border-top: 1px solid #f5f5f5;
								border-radius: 10px;
								a {
									border-radius: 9px;
								}
								@include max-screen(767px) {
									border-top: 0;
								}
							}
							&:hover {
								a {
									background: #3dcec5;
									color: #fff;
									span {
										color: #fff;
										&:after {
											border-color: #fff;
										}
									}
								}
							}
						}
					}
				}
				&.canHover:hover,
				&.canHover.active {
					&:before {
						opacity: 1;
					}
				}
			}
		}
		@include max-screen(767px) {
			border-bottom: 0;
			overflow-y: scroll;
			height: 0;
			transition: 0.3s ease all;
			padding: 0;
			-webkit-overflow-scrolling: touch;
			&__master {
				>li {
					display: block;
					width: auto;
					border: 0;
					border-bottom: 1px solid #cdcdcd;
					&:first-child {
						border: 0;
						border-bottom: 1px solid #cdcdcd
					}
					&:last-child {
						border: 0;
					}
					a {
						text-align: left;
						i {
							width: 34px;
							height: 34px;
							background-size: auto 18px;
						}
					}
					&#parent01 a i {
						background-position: 7px 6px;
					}
					&#parent02 a i {
						background-position: -195px 6px;
					}
					&#parent03 a i {
						background-position: -386px 6px;
					}
					&#parent04 a i {
						background-position: -608px 6px;
					}
				}
				>li.parent {
					>a {
						position: relative;
						&:after,
						&:before {
							content: "";
							display: block;
							width: 20px;
							height: 2px;
							border-top: 2px solid #aaa;
							position: absolute;
							top: 50%;
							right: 5px;
							margin-top: -1px;
							transition: 0.3s ease all;
						}
						&:before {
							@include transform_c(rotate(90deg));
						}
					}
					ul {
						display: block;
						position: relative;
						top: auto;
						padding: 10px;
						border-top: 1px dashed #ddd;
						display: none;
						li {
							border: 0;
							&:first-child {
								border: 0;
								a:before {
									display: none;
								}
							}
							&:last-child {
								border: 0;
							}
							a {
								padding: 0;
								span {
									border: 0;
								}
							}
						}
					}
					&.openUl {
						>a {
							&:after {
								@include transform_c(rotate(180deg));
							}
							&:before {
								@include transform_c(rotate(0deg));
							}
						}
						ul {}
					}
				}
			}
		}
	}
}
