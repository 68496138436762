@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
#top {
	.sArchive {display: none;}
	.detailMainLeft .blockContent {margin-top: 0;}
	.rankBlock {margin-bottom: 40px;border-bottom: 2px solid #bfc7d6;
		@include max-screen(767px) {margin-bottom: 30px;}
	}
	.searchBox  {border-bottom: none;}


	.headTop {
		width:100%;
		height:100%;
		margin: 0;
		overflow: hidden;
		//background: url(../img/bg_main.png) no-repeat center center;
		// background: linear-gradient(270deg, #499ccb, #51d3cb, #f14978, #fd9999, #fbb217, #ffe156);
		// background: -moz-linear-gradient(270deg, #499ccb, #51d3cb, #f14978, #fd9999, #fbb217, #ffe156);
		// background: -webkit-linear-gradient(270deg, #499ccb, #51d3cb, #f14978, #fd9999, #fbb217, #ffe156);
		// filter: progid:DXImageTransform.Microsoft.gradient(270deg, #499ccb, #51d3cb, #f14978, #fd9999, #fbb217, #ffe156);
		// background-size: 1200% 1200%;

		// -webkit-animation: AnimationName 40s ease infinite;
		// -moz-animation: AnimationName 40s ease infinite;
		// animation: AnimationName 40s ease infinite;

		// .inner {
			
		// 	max-width: 1200px;
		// 	margin: 0 auto;
		// 	&.bg01 {
		// 		background: url('../img/common/other/bg01.jpg') repeat-x center top;
		// 	}
		// 	&.bg02 {
		// 		background: url('../img/common/other/bg02.jpg') repeat-x center top;
		// 	}
		// 	&.bg03 {
		// 		background: url('../img/common/other/bg03.jpg') repeat-x center top;
		// 	}
		// 	.content {
		// 		max-width: 64%;
		// 		margin-left: 7%;
		// 		position: relative;
		// 		height: 400px;
		// 		padding-top: 37px;
		// 		.mainTxt {
		// 			max-width: 67.4%;
		// 			max-width: 673px;
		// 			display: block;
		// 			img{display: block;}
		// 			padding-bottom: 20px;
		// 		}
		// 		a {
		// 			display: inline-block;
		// 			width: 74%;
		// 			text-align: center;
		// 			border: 3px solid #000;
		// 			@include border-radius(8px);
		// 			line-height: 56px;
		// 			font-weight: bold;
		// 			font-size: 20px;
		// 			color: #000;
		// 			-webkit-box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.4);
		// 			-moz-box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.4);
		// 			box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.4);
		// 			background: #fff;
		// 			margin-left: 9%;
		// 			&:hover {opacity: 0.6;}
		// 		}
		// 		.charaTop {
		// 			display: block;
		// 			position: absolute;
		// 			bottom: 0;
		// 			right: -10px;
		// 			max-width: 15.2%;
		// 			img {max-width: 116px;display: block;}
		// 		}
		// 	}

		// @include max-screen(767px) {
		// 		background: none;
		// 		.content {
		// 			max-width: 100%;
		// 			margin-left: 0;
		// 			height: auto;
		// 			padding: 20px 20px 15px;
		// 			.mainTxt {
		// 				max-width: 100%;
		// 				padding-bottom: 10px;
		// 				margin-left: -5px;
		// 			}
		// 			a {
		// 				width: 100%;
		// 				border: 2px solid #000;
		// 				@include border-radius(5px);
		// 				line-height: 35px;
		// 				font-size: 11px;
		// 				margin-left: 0;
		// 				position: relative;
		// 				z-index: 2;
		// 			}
		// 			.charaTop {
		// 				bottom: 45px;
		// 				right: 33px;
		// 				max-width: 22%;
		// 				z-index: 1;
		// 				img {max-width: 100%;}
		// 			}
		// 		}
		// 	}
		// }

		.top-slider {
			margin: 0;
			.item {
				height: 398px;
				overflow: hidden;
				@include max-screen(767px) {
					height: auto;
				}
				&.item1 {
					background: url('../img/common/other/bg01.jpg') repeat center top;
					background-size: cover;
					@include max-screen(767px) {
						background: url('../img/common/other/bg01_sp.jpg') repeat center top;
						background-size: cover;
					}
				}
				&.item2 {
					background: url('../img/common/other/bg02.jpg') repeat center top;
					background-size: cover;
					@include max-screen(767px) {
						background: url('../img/common/other/bg02_sp.jpg') repeat center top;
						background-size: cover;
					}
				}
				&.item3 {
					background: url('../img/common/other/bg03.jpg') repeat center top;
					background-size: cover;
					@include max-screen(767px) {
						background: url('../img/common/other/bg03_sp.jpg') repeat center top;
						background-size: cover;
					}
				}
				.item-inner {
					max-width: 1120px;
					margin: 0 auto;
					position: relative;
					padding: 30px 0;
					@include max-screen(767px) {
						padding: 20px 20px;
						text-align: center;
					}
					&:before {
						content: '';
						background: url('../img/common/other/bg_img.png') no-repeat 0 0;
						background-size: 460px 398px;
						width: 460px;
						height: 398px;
						position: absolute;
						right: 0;
						top: 0;
						z-index: 1;
						@include max-screen(1120px) {
							width: 400px;
						}
						@include max-screen(993px) {
							width: 310px;
						}
						@include max-screen(767px) {
							display: none;
						}
					}
					// &:after {
					// 	content: '';
					// 	background: url('../img/top_chara.png') no-repeat 0 0;
					// 	background-size: 100% auto;
					// 	width: 10%;
					// 	height: 186px;
					// 	position: absolute;
					// 	right: 30%;
					// 	bottom: -37px;
					// 	z-index: 10;
					// }
					.ttl {
						width: 60.09%;
						display: block;
						img{display: block;}
						padding-bottom: 20px;
						position: relative;
						z-index: 2;
						@include max-screen(767px) {
							width: 100%;
							padding: 0;
						}
					}
					.imgsp {
						@include max-screen(767px) {
							img {
								width: 100%;
							}
						}
					}
					a {
						display: inline-block;
						width: 50%;
						text-align: center;
						border: 3px solid #000;
						@include border-radius(8px);
						line-height: 56px;
						font-weight: bold;
						font-size: 20px;
						color: #000;
						outline: 0;
						-webkit-box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.4);
						-moz-box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.4);
						box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.4);
						background: #fff;
						margin-left: 7%;
						position: relative;
						z-index: 10;
						&:hover {
							opacity: 0.6;
						}
						@include max-screen(767px) {
							width: 90%;
							margin: 0 auto;
							width: 100%;
							border: 2px solid #000;
							@include border-radius(5px);
							line-height: 35px;
							font-size: 11px;
						}
					}
				}
			}
		}
	}

	.recommendBox {
		background:  #f14978;
		padding: 10px;
		margin-bottom: 50px;
		border-bottom: 2px solid #bfc7d6;
		h3 {
			text-align: center;
			color: #fff;
			font-size: 30px;
			font-weight: bold;
			padding: 12px 0 15px;
		}
		.inner {
			background: #fff;
			padding: 36px 30px 30px;
			> p {
				font-size: 15px;
				margin: 17px 0;
				line-height: 1.8;
			}
			div.clearfix {
				.thumb {
					float: left;
					width: 47.2%;
					max-width: 299px;
					border: 1px solid #cdcdcd;
					a,img{display: block;}
				}
				ul {
					float: right;
					width: 47%;
					li {
						font-size: 22px;
						font-weight: bold;
						color: #f14978;
						position: relative;
						padding-left: 30px;
						&:after {
							content: '★';
							position: absolute;
							top: 5px;
							left: 0;
							line-height: 1;
							font-size: 22px;
							color: #f14978;
							font-weight: bold;
						}
					}
				}
			}
		}
		@include max-screen(767px) {
			padding: 5px;
			margin-bottom: 25px;
			h3 {
				font-size: 16px;
				padding: 10px 0;
			}
			.inner {
				padding: 10px 10px 17px;
				> p {
					font-size: 12px;
					margin: 10px 0;
				}
				div.clearfix {
					.thumb {
						float: none;
						width: 100%;
						max-width: 100%;
						margin-bottom: 18px;
						img {width: 100%;}
					}
					ul {
						float: none;
						width: 100%;
						li {
							font-size: 16px;
							padding-left: 22px;
							&:after {
								font-size: 16px;
								top: 4px;
							}
						}
					}
				}
			}
		}
	}
}
.sortBox {
	padding-bottom: 12px;
	h3 {
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: #144cb3;
		padding-bottom: 20px;
		em {
			font-style: normal;
			font-size: 24px;
		}
	}
	.form-sort {
		background: #144cb3;
		padding: 20px;
		@include border-radius(5px);
		@include clearfix();
		> strong {
			font-weight: bold;
			font-size: 24px;
			color: #fff;
			display: inline-block;
			float: left;
			line-height: 30px;
			@include max-screen(999px) {
				font-size: 20px;
			}
		}
		> span {
			display: inline-block;
			float: right;
			color: #fff;
			font-size: 15px;/* The CSS */
			i {font-style: normal;}
			select {
			    padding:3px;
			    margin: 0;
			    -webkit-border-radius:0;
			    -moz-border-radius:0;
			    border-radius:0;
			    -webkit-box-shadow: none;
			    -moz-box-shadow: none;
			    box-shadow: none;
			    background: #fff;
			    color:#888;
			    border:none;
			    outline:none;
			    display: inline-block;
			    -webkit-appearance:none;
			    -moz-appearance:none;
			    appearance:none;
			    cursor:pointer;
			    width: 200px;
			    height: 30px;
			}

			/* Targetting Webkit browsers only. FF will show the dropdown arrow with so much padding. */
			@media screen and (-webkit-min-device-pixel-ratio:0) {
			    select {padding-right:18px; padding-left: 10px; color: #222;}
			}

			label {position:relative; margin-left: 10px;}
			label:after {
				content: "";
				position: absolute;
				right: 8px;
				top: 50%;
				margin-top: -4px;
				width: 0;
				height: 0;
				border-bottom: 4px solid transparent;
				border-left: 8px solid #222;
				border-top: 4px solid transparent;
			    -moz-transform: rotate(90deg);
				-webkit-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				transform: rotate(90deg);
			}
		}
	}
	@include max-screen(767px) {
		padding-bottom: 10px;
		margin: 0 -5px;
		h3 {
			text-align: center;
			font-size: 15px;
			font-weight: bold;
			color: #144cb3;
			padding-bottom: 20px;
			em {
				font-style: normal;
				font-size: 18px;
			}
		}
		.form-sort {
			padding: 10px;
			@include border-radius(0);
			> strong {
				font-size: 13px;
				line-height: 20px;
			}
			> span {
				font-size: 12px;/* The CSS */
				select {
				    width: 100px;
				    height: 20px;
				    line-height: 14px;
				}

				label {margin-left: 0;}
				label:after {
					right: 5px;
					margin-top: -3px;
				}
			}
		}
	}
}













