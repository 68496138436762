@charset 'UTF-8';


/*-----------------------------------------------------------
Footer
------------------------------------------------------------*/
.footer {background: #144cb3; letter-spacing: 1px;
	&__col {float: left; width: 25%;
		&__inner {padding: 40px 15px 53px 0;
			h4 {font-size: 18px; color: #fff; font-weight: bold; line-height: 1; margin-bottom: 16px;
				@include max-screen(1000px) {font-size: 16px;}
				a {color: #fff;}
			}
			ul {
				li {
					a {font-size: 13px; color: #fff; text-indent: -12px; padding-left: 19px; display: block; line-height: 2;
						&:hover {opacity: 0.8;}
						@include max-screen(1000px) {font-size: 11px;}
						i {font-style: normal;}
					}
				}
			}
		}
	}
	&__copyright {background: #ffffff; font-size: 12px; line-height: 50px;
		&__left {float: left;
			a {color: #144cb3; text-decoration: underline;
				&:hover {text-decoration: none;}
			}
		}
		&__right {float: right;}
	}
	@include max-screen(767px) {
		&__upToTop {font-size: bold; font-size: 13px; text-align: center; color: #b3b3b3; background: #f5f5f5; line-height: 50px;
			span {display: inline-block; position: relative;
				&:after {content: ""; display: inline-block; width: 10px; height: 10px; border-top: 1px solid #aaa; border-right: 1px solid #aaa; @include transform_c(rotate(-45deg)); margin-left: 10px; position: relative; top: 3px;}
			}
		}
		&__col {float: none; width: auto; padding: 0; margin-left: -15px; margin-right: -15px;
			&__inner {padding: 0;
				h4 {font-size: 13px; margin: 0; line-height: 44px; padding: 0 15px; border-top: 1px solid #02177e; position: relative; background: 15px center no-repeat; padding-left: 50px; background-size: 21px 21px;
					&.hasChildren:after, &.hasChildren:before {content: ""; display: block; width: 20px; height: 2px; border-top: 2px solid #fff; position: absolute; top: 50%; right: 15px; margin-top: -1px; transition: 0.3s ease all;}
					&.hasChildren:before {@include transform_c(rotate(90deg));}
				}
				ul {background: #fff; display: none;
					li {margin-bottom: 1px; padding: 0;
						&:last-child {margin-bottom: 0;}
						a {padding: 0 0 0 15px; overflow: hidden; line-height: 45px; color: #144cb3; background: #e3edff; position: relative; text-indent: 0;
							i {display: none;}
							&:hover {}
							&:after {content: ""; display: inline-block; width: 10px; height: 10px; border-top: 1px solid #144cb3; border-right: 1px solid #144cb3; @include transform_c(rotate(45deg)); margin-left: 10px; position: absolute; top: 50%; right: 15px; margin-top: -4px;}
						}
					}
				}
				&.openUl {
					h4 {
						&:after {@include transform_c(rotate(180deg));}
						&:before {@include transform_c(rotate(0deg));}
					}
					ul {
						li {
							a {}
						}
					}
				}
			}
			&#fCol01 h4 {background-image: url(../img/common/footer/ico01SP.png);}
			&#fCol02 h4 {background-image: url(../img/common/footer/ico02SP.png);}
			&#fCol03 h4 {background-image: url(../img/common/footer/ico03SP.png);}
			&#fCol04 h4 {background-image: url(../img/common/footer/ico04SP.png);}
		}
		&__copyright {padding-top: 13px;
			&__left {float: none; width: auto; text-align: center; line-height: 25px; font-size: 10px;
				a {
					&:hover {}
				}
			}
			&__right {float: none; width: auto; text-align: center; line-height: 38px; font-size: 9px;}
		}
	}
}