#company {
	.sArchive {display: none;}
	.companyTbl {
		width: 100%;
		font-size: 14px;
		th {
			font-size: 15px;
			font-weight: bold;
			text-align: left;
			background: #f0f0f0;
			border: 1px solid #cdcdcd;
			width: 220px;
			padding: 20px 30px;
		}
		td {
			border: 1px solid #cdcdcd;
			padding: 20px;
		}
	}
	#wrap main .mainLeft .contentMainLeft {padding-top: 30px;}
	@include max-screen(767px) {
		.companyTbl {
			font-size: 11px;
			th {
				font-size: 11px;
				font-weight: normal;
				width: 113px;
				padding: 10px;
			}
			td {
				padding: 10px;
			}
		}
		#wrap main .mainLeft .contentMainLeft {padding-top: 10px; margin-bottom: 25px;}
	}
}
@include max-screen(767px) {
.btnAds {margin: 25px 28px;}
}